export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,11];

export const dictionary = {
		"/": [12],
		"/(privileged)/admin": [14,[2,4],[3]],
		"/(privileged)/admin/assessment-tools/form-validation": [15,[2,4,5],[3]],
		"/(privileged)/admin/assessment-tools/launch": [16,[2,4,5],[3]],
		"/(privileged)/admin/roster": [17,[2,4],[3]],
		"/(privileged)/educator": [18,[2],[3]],
		"/exam/[examPlanId]": [21,[6,7],[,8]],
		"/exam/[examPlanId]/[examPlansResultsId]/[taskItemsIndex]/[taskItemLabel]/proctor": [22,[6,7,9,10],[,8]],
		"/exam/[examPlanId]/[examPlansResultsId]/[taskItemsIndex]/[taskItemLabel]/student": [23,[6,7,9,10],[,8]],
		"/(privileged)/landing": [19,[2],[3]],
		"/lobby": [24],
		"/(privileged)/proctor": [20,[2],[3]],
		"/signin": [25],
		"/signout": [26],
		"/student-game": [27,[11]],
		"/switch-role": [28],
		"/themes/admin": [29],
		"/themes/student": [30],
		"/(privileged)/[...path]": [13,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';